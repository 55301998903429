import states from './states'
import _ from 'lodash'
import moment from 'moment'

export const nameFormatter = (firstName, middleName, lastName, suffix) => {
  const mid = middleName ? `${middleName} ` : ''
  const suf = suffix ? ` ${suffix}` : ''
  return `${firstName} ${mid}${lastName}${suf}`
}

const regexTests = {
  'YYYY-MM-DD': new RegExp(/(\d{4})-(\d{2})-(\d{2})/),
  email: new RegExp(
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ),
  alphanum: new RegExp(/^[a-z0-9]+$/i),
}

const NULL_DATE = moment('0001-01-01T00:00:00Z').utc()

export const isNullDate = (val) => {
  const date = moment(val)

  return date.diff(NULL_DATE) === 0
}

export const isEmail = (val) => {
  return regexTests.email.test(val)
}

export const validDate = (val) => {
  return regexTests['YYYY-MM-DD'].test(val)
}

export const validString = (val) => {
  return regexTests.alphanum.test(val)
}

export const validZip = (val) => {
  return val && _.isNumber(val * 1) && val.length === 5
}

export const validState = (val) => {
  if (!val || val.length !== 2) return false
  const formatted = val.toUppercase()

  return states[formatted] !== undefined
}

export const validPhone = (val) => {
  return true
}

export const validEmail = (val) => {
  return isEmail(val)
}

/**
 * Returns true if a user is on mobile.
 * This is determined by a viewport width under 500
 * List of viewport sizes that fit within this can be found here: https://mediag.com/blog/popular-screen-resolutions-designing-for-all/
 */
export const isUserOnMobile = () => {
  if (window.innerWidth < 500) {
    return true
  }

  return false
}

export const download = (url, name) => {
  // create an anchor element and "click" it
  // note that this is subject to normal browser limitations on user interactions
  // ie it must be triggered by a user action within an acceptable timeframe
  const downloader = document.createElement('a')

  downloader.href = url
  downloader.download = name
  document.body.appendChild(downloader)
  downloader.click()
  document.body.removeChild(downloader)
}
